<template>
  <div>
    <div
      v-if="!inline"
      v-tooltip.noMobile="
        $t(isLoved ? 'home.remove_from_saved_homes' : 'home.add_to_saved_homes')
      "
      class="home-save"
      :class="{ 'home-save--loved': isLoved }"
      @click.prevent.stop="toggleLike()"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28.003"
        height="24.878"
        viewBox="0 0 28.003 24.878"
      >
        <path
          id="Icon_awesome-heart"
          data-name="Icon awesome-heart"
          d="M22.573,3.743a6.677,6.677,0,0,0-9.111.664L12.5,5.4l-.962-.991a6.677,6.677,0,0,0-9.111-.664,7.011,7.011,0,0,0-.483,10.151l9.448,9.756a1.531,1.531,0,0,0,2.212,0l9.448-9.756a7.007,7.007,0,0,0-.479-10.151Z"
          transform="translate(1.502 -0.745)"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28.003"
        height="24.878"
        viewBox="0 0 28.003 24.878"
        class="fake"
      >
        <path
          id="Icon_awesome-heart"
          data-name="Icon awesome-heart"
          d="M22.573,3.743a6.677,6.677,0,0,0-9.111.664L12.5,5.4l-.962-.991a6.677,6.677,0,0,0-9.111-.664,7.011,7.011,0,0,0-.483,10.151l9.448,9.756a1.531,1.531,0,0,0,2.212,0l9.448-9.756a7.007,7.007,0,0,0-.479-10.151Z"
          transform="translate(1.502 -0.745)"
        />
      </svg>
    </div>
    <a v-else class="home-save-inline" @click.prevent="toggleLike()">
      <hb-transition name="fade">
        <nuxt-icon v-if="!isLoved" name="heart" filled></nuxt-icon>
        <nuxt-icon
          v-else
          class="home-save-inline__filled"
          name="heart-filled"
          filled
        ></nuxt-icon>
      </hb-transition>
      <span class="home-save-inline__text">{{ $t('label.save') }}</span>
    </a>
  </div>
</template>

<script lang="ts">
import { ref, watch } from 'vue'
import { getApiError } from '~/utils'
import { useApiHandler, useDM, useToasts } from '~/composables'
import HbTransition from '~/components/base/utils/HbTransition.vue'
import AuthDialog from '~/modules/auth/components/dialogs/AuthDialog.vue'
import { usePropertyStore, useUserStore } from '~/stores'
import { useI18n } from '#imports'

export default {
  name: 'HomeSaveBtn',
  components: { HbTransition },
  props: {
    homeId: { type: Number, required: true },
    isSaved: { type: Boolean, default: () => false },
    inline: { type: Boolean, default: () => false },
    undoableDislike: { type: Boolean, default: () => false },
  },
  emits: ['saved', 'unsaved'],
  setup(props, ctx) {
    const { t } = useI18n()
    const isLoved = ref<boolean>(props.isSaved)
    const propertyStore = usePropertyStore()
    const { show: showToast } = useToasts()
    const emitSaved = () => ctx.emit('saved')
    const emitUnsaved = () => ctx.emit('unsaved')
    const { execute, pending } = useApiHandler(async () => {
      const originValue = isLoved.value

      try {
        isLoved.value = !isLoved.value

        if (originValue) {
          await propertyStore.dislike(props.homeId)

          if (props.undoableDislike) {
            const toast = showToast({
              text: t('home.unsaved_home'),
              linkText: t('label.undo'),
              timeout: 5000,
              linkCb: () => {
                toggleLike()
                toast.hide()
              },
            })
          }

          emitUnsaved()
        } else {
          await propertyStore.like(props.homeId)
          emitSaved()
        }
      } catch (e: any) {
        isLoved.value = originValue
        const message =
          (await getApiError(e))?.message || t('home.property_action_failed')
        useToasts().show({
          text: message,
          theme: 'warning',
        })
      }
    })
    const toggleLike = async () => {
      if (!useUserStore().isAuthenticated()) {
        useDM().open(AuthDialog, {
          afterClose(success?: boolean) {
            if (success) {
              execute()
            }
          },
        })
        return
      }

      if (!pending.value) {
        await execute()
      }
    }

    watch(
      () => props.isSaved,
      () => (isLoved.value = !!props.isSaved)
    )

    return {
      isLoved,
      toggleLike,
    }
  },
}
</script>

<style scoped lang="scss">
.home-save {
  padding: 2px;
  cursor: pointer;
  line-height: 0;

  svg {
    fill: rgba(0, 0, 0, 0.25);
    stroke: rgb(255, 255, 255);
    stroke-linecap: round;
    stroke-width: 3;
  }

  .fake {
    position: absolute;
    right: 2px;
    top: 2px;
    fill: var(--hb-primary);
    stroke: var(--hb-primary);
    opacity: 0;
  }

  &--loved {
    .fake {
      animation: loved 0.8s;
    }

    @keyframes loved {
      0% {
        transform: scale(1.2);
        opacity: 1;
      }
      70% {
        transform: scale(1.7);
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }

    svg {
      fill: var(--hb-primary);
      stroke: var(--hb-primary);
    }
  }
}

.home-save-inline {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
  transition: 0.3s all;

  .nuxt-icon {
    margin-right: 12px;
    font-size: 18px;
    color: var(--hb-blue1);
    transition: 0.3s all;

    &.home-save-inline__filled {
      color: var(--hb-primary);
    }
  }

  &:hover {
    color: var(--hb-primary);

    .nuxt-icon {
      color: var(--hb-primary);

      &.home-save-inline__filled {
        color: var(--hb-red2);
      }
    }
  }

  &__text {
    @include mobile {
      display: none;
    }
  }
}
</style>
